<template>
	<b-popover :target="target" triggers="focus" :placement="mobiledevise" >
		<i class="fa " :class = "{'text-danger fa-times': !passwordlength, 'text-success fa-check': passwordlength} "></i> at least 8 characters<br>
		<i class="fa" :class = "{'text-danger fa-times': !lowercase, 'text-success fa-check': lowercase} "></i> 1 lowercase character<br>
		<i class="fa"  :class = "{'text-danger fa-times': !uppercase, 'text-success fa-check': uppercase} "></i> 1 uppercase character<br>
		<i class="fa" :class = "{'text-danger fa-times': !hasNumber, 'text-success fa-check': hasNumber}"></i> 1 numeric character
	</b-popover>
</template>

<script type="text/javascript"></script>

<script>
	export default{
		name: 'popoverpassword',
		data(){
			return{}
		},

		props:{
			target:String,
			check:String,
			mobiledevise:String 
		},

		computed:{
			hasNumber() {
				return /\d/.test(this.check);
			},


			passwordlength(){
				return this.check.length >= 8
			},

			uppercase(){
				return (/[A-Z]|[\u0080-\u024F]/.test(this.check));
			},

			lowercase(){
				return (/[a-å]/.test(this.check));
			},

		}

	}
</script>