import {baseurl} from './index.js'

import axios from 'axios'

axios.defaults.baseURL = baseurl

//axios.defaults.headers.common['flowe-event-id'] = this.$store.getters.logeventId

export default {

	Invitationemail( userid, email, context){
		return axios.get(  "/Rest/Sendemail?sendtype=invite&uuid=" + userid + "&email=" + email + "&context=" + context)
	},
	Invitationorgemail( userid, email, context){
		return axios.get(  "/Rest/Sendemail?sendtype=inviteorg&uuid=" + userid + "&email=" + email + "&context=" + context)
	},

	Hasbeenapprovedemailtouser( userid, mid, uuid){
		return axios.get(  "/Rest/Sendemail?sendtype=hasbeenapproveduser&userid="+userid+"&mid="+mid+'&uuid='+uuid)
	},

	Hasbeenapprovedemailtoowner( userid, mid, owner, uuid){
		return axios.get(  "/Rest/Sendemail?sendtype=hasbeenapprovedowners&userid="+userid + "&mid="+mid+"&owner="+owner+'&uuid='+uuid )
	},

	Hasbeenrejectedemailtouser( userid, mid, uuid){
		return axios.get( "/Rest/Sendemail?sendtype=hasbeenrejected&userid="+userid +"&mid="+mid+'&uuid='+uuid)
	},

	Hasbeenrejectedemailtoowner( userid, mid, owner, uuid){
		return axios.get( "/Rest/Sendemail?sendtype=hasbeenrejectedowners&userid="+userid+"&mid="+mid+"&owner="+owner +'&uuid='+uuid )
	},

	Passwordchanged( token ){
		return axios.get( "/Rest/Sendchangeprofileemail?passwordhaschanged=true&signinlocalchanged=false&signinlocalnewvalue=enabled&signingoolechanged=false&signingoolenewvalue=" + "&t="+token )
	},
	
	Emailpassworddisabled( token ){
		return axios.get( "/Rest/Sendchangeprofileemail?passwordhaschanged=false&signinlocalchanged=true&signinlocalnewvalue=disabled&signingoolechanged=false&signingoolenewvalue=" + "&t="+token )
	},

	Emailpasswordenabled( token ){
		return axios.get( "/Rest/Sendchangeprofileemail?passwordhaschanged=false&signinlocalchanged=true&signinlocalnewvalue=enabled&signingoolechanged=false&signingoolenewvalue=" + "&t="+token )
	},
	Googlesignindisabled( token ){
		return axios.get( "/Rest/Sendchangeprofileemail?passwordhaschanged=false&signinlocalchanged=false&signinlocalnewvalue=&signingoolechanged=true&signingoolenewvalue=disabled" + "&t="+token )
	},
	Googlesigninenabled( token ){
		return axios.get( "/Rest/Sendchangeprofileemail?passwordhaschanged=false&signinlocalchanged=false&signinlocalnewvalue=&signingoolechanged=true&signingoolenewvalue=enabled" + "&t="+token )
	}


}

